import { loadCategoryDetails } from "../../state/modules/category/actions/loadCategoryDetails";
import shouldGetCategory from "./shouldGetCategory";

const getCategoryDetailsIfNeeded = async ({
  dispatch,
  query,
  country,
  language,
  uuid,
  xSiteOrigin,
  isNewPage,
  shouldReloadCategoryDetails,
  refineForCategoryDetails
}) => {
  if (shouldGetCategory(isNewPage)) {
    return dispatch(
      loadCategoryDetails({
        query,
        country,
        language,
        uuid,
        xSiteOrigin
      })
    );
  }

  if (shouldReloadCategoryDetails) {
    const catDetailsQuery = { ...query, refine: refineForCategoryDetails };

    return dispatch(
      loadCategoryDetails({
        query: catDetailsQuery,
        country,
        language,
        uuid,
        xSiteOrigin
      })
    );
  }
};

export default getCategoryDetailsIfNeeded;
