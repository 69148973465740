const seoFlagEncoded = "&lt;!--friendly--&gt;";
const seoFlagDecoded = "<!--friendly-->";

export const isSeoFriendly = headerHTML =>
  headerHTML.startsWith(seoFlagEncoded) ||
  headerHTML.startsWith(seoFlagDecoded);

export const removeSeoFlagDecoded = str =>
  str.replace(seoFlagEncoded, "").replace(seoFlagDecoded, "").trim();

export const removeHtmlTags = string =>
  string
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/(<([^>]+)>)/g, "")
    .trim();

export const getHeaderTitle = ({
  headerTitle,
  friendlyName,
  headerHtmlEncoded
}) =>
  headerTitle ||
  (isSeoFriendly(headerHtmlEncoded)
    ? removeHtmlTags(removeSeoFlagDecoded(headerHtmlEncoded))
    : friendlyName);
