import { useRef, useState } from "react";
import PropTypes from "prop-types";
import CategoryHeaderAccordion from "./CategoryHeaderAccordion";
import CategoryHeaderMedia from "./CategoryHeaderMedia";
import CategoryHeaderTitleOverlay from "./CategoryHeaderTitleOverlay";
import { VIDEO_HEADER } from "../../../../constants";
import { getHeaderTitle } from "../../../helpers.js";
import * as types from "./constants";
import styles from "./CategoryHeaderHero.css";

const CategoryHeaderHero = ({
  category,
  mediaType,
  scrollFunction,
  viewportCategory
}) => {
  const videoRef = useRef(null);
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);
  const {
    id,
    headerTitle,
    friendlyName,
    headerBody,
    headerHtmlEncoded
  } = category;
  const header = getHeaderTitle({
    headerTitle,
    friendlyName,
    headerHtmlEncoded
  });

  const toggleDescription = () => {
    setIsDescriptionOpen(!isDescriptionOpen);
  };

  return (
    <div id={VIDEO_HEADER} className={styles.heroHeader}>
      <div className={styles.heroHeaderContainer}>
        <CategoryHeaderMedia
          ref={videoRef}
          categoryId={id}
          mediaType={mediaType}
          viewportCategory={viewportCategory}
        />
        <CategoryHeaderTitleOverlay
          headerTitle={header}
          scrollFunction={scrollFunction}
        />
      </div>
      {category.headerBody && (
        <CategoryHeaderAccordion
          content={headerBody}
          onClick={toggleDescription}
          isOpen={isDescriptionOpen}
        />
      )}
    </div>
  );
};

CategoryHeaderHero.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.number,
    headerBody: PropTypes.string,
    friendlyName: PropTypes.string,
    headerHtmlEncoded: PropTypes.string,
    headerTitle: PropTypes.string
  }).isRequired,
  mediaType: PropTypes.oneOf(Object.values(types)).isRequired,
  scrollFunction: PropTypes.func.isRequired,
  viewportCategory: PropTypes.string.isRequired
};

export default CategoryHeaderHero;
