import PropTypes from "prop-types";
import classNames from "classnames/bind";
import styles from "./index.css";

const styleContext = classNames.bind(styles);

const CategoryHeaderAccordion = ({ onClick, isOpen, content }) => (
  <button
    className={styles.categoryHeaderAccordion}
    onClick={onClick}
    onKeyDown={onClick}
  >
    <p
      className={styleContext(styles.description, {
        open: isOpen
      })}
    >
      {content}
    </p>
    <div
      className={classNames(styles.icon, {
        [styles.iconUp]: isOpen
      })}
    />
  </button>
);

CategoryHeaderAccordion.propTypes = {
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  content: PropTypes.string.isRequired
};

export default CategoryHeaderAccordion;
