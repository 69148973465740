import { useSelector } from "react-redux";
import { getCategoryChildLinks } from "../../state/modules/category";

import CategoryHeader from "./CategoryHeader";

const CategoryHeaderContainer = () => {
  const hasHeaderLinks = useSelector(getCategoryChildLinks);

  return <CategoryHeader hasHeaderLinks={!!hasHeaderLinks.length} />;
};

export default require("../../containers/withTogglePoint").default(CategoryHeaderContainer, require.context("./__variants__/", true, /^\.\/([^/]+\/){2}index\.js$/))
