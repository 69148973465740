import { setIsNewPage, setPageLoading } from "../../state/modules/page/actions";
import { sendPlacementOnLoadBeacon } from "../../state/modules/search";
import { analyticsPageLoad } from "../../state/modules/analytics/actions";

const handleFetchSuccess = ({ dispatch, isNewPage }) => {
  dispatch(setPageLoading(false));
  dispatch(sendPlacementOnLoadBeacon());
  if (isNewPage) {
    dispatch(analyticsPageLoad());
    dispatch(setIsNewPage(false));
  }
};

export default handleFetchSuccess;
