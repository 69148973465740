import { useEffect } from "react";
import shouldAutoPlay from "./shouldAutoPlay";

const useAutoPlay = ({
  videoRef,
  setIsVideoPaused,
  setShouldVideoStayPaused
}) => {
  const autoPlay = !!videoRef.current && shouldAutoPlay();

  useEffect(() => {
    if (autoPlay) {
      setIsVideoPaused(false);
      setShouldVideoStayPaused(false);
      videoRef.current.play();
    }
  }, [autoPlay]);

  return autoPlay;
};

export default useAutoPlay;
