import PropTypes from "prop-types";
import Pill from "../Pill";
import HorizontalScrollList from "../../HorizontalScrollList";
import styles from "./CategoryHeaderLinks";

const CategoryHeaderLinks = ({ childLinks }) => (
  <HorizontalScrollList
    scrollSnapping={false}
    className={styles.categoryHeaderLinks}
  >
    {childLinks.map(({ friendlyName, linkUrl }) => (
      <Pill key={friendlyName} friendlyName={friendlyName} linkUrl={linkUrl} />
    ))}
  </HorizontalScrollList>
);

CategoryHeaderLinks.propTypes = {
  childLinks: PropTypes.arrayOf(
    PropTypes.shape({
      friendlyName: PropTypes.string.isRequired,
      linkUrl: PropTypes.string.isRequired
    })
  ),
  className: PropTypes.string
};

export default CategoryHeaderLinks;
