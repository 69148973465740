import { useSelector } from "react-redux";
import { getViewportCategory } from "../../../state/modules/layout/selectors";
import { getCategoryHeaderDescription } from "../../../state/modules/category";
import { withTranslation } from "../../../components/translation";
import CategoryHeaderDescription from "./CategoryHeaderDescription";

const CategoryHeaderDescriptionContainer = () => {
  const viewport = useSelector(getViewportCategory);
  const description = useSelector(getCategoryHeaderDescription);

  return (
    <CategoryHeaderDescription viewport={viewport} description={description} />
  );
};

export default withTranslation(CategoryHeaderDescriptionContainer);
