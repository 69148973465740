import getClientConfigValue from "../../../client/getClientConfigValue";
import config from "../../../config/common";

const {
  api: {
    curatedCategory: { path: curatedCategoryApiPath }
  }
} = config;

export default () => {
  if (SERVER) {
    return `${process.env.CURATED_CATEGORY_API_HOSTNAME}${curatedCategoryApiPath}`;
  }

  if (getClientConfigValue("LOCAL_DEVELOPMENT") === "true") {
    return `/api${curatedCategoryApiPath}`;
  }

  return `${getClientConfigValue("BASE_URL")}${curatedCategoryApiPath}`;
};
