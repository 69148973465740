import { useEffect, useState } from "react";

const usePauseVideo = ({
  isMediumViewportOrGreater,
  videoRef,
  shouldVideoStayPaused
}) => {
  const [wasOutOfView, setWasOutOfView] = useState(false);

  const observerCallback = ([entry]) => {
    if (!shouldVideoStayPaused) {
      if (entry.isIntersecting) {
        if (wasOutOfView) {
          videoRef.current.play();
        }
      } else {
        videoRef.current.pause();
        setWasOutOfView(true);
      }
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, {
      rootMargin: isMediumViewportOrGreater ? "30px" : "50px"
    });

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, [videoRef.current, isMediumViewportOrGreater, observerCallback]);
};

export default usePauseVideo;
