import {
  SMALL_VIEWPORT,
  MEDIUM_VIEWPORT,
  LARGE_VIEWPORT
} from "../../../../../../state/modules/layout/constants";

const mobilePosterUrlTemplateFactory = categoryId =>
  `https://content.asos-media.com/-/media/images/curated-categories/featured-header/${categoryId}-mobile.jpg`;

const desktopPosterUrlTemplateFactory = categoryId =>
  `https://content.asos-media.com/-/media/images/curated-categories/featured-header/${categoryId}-desktop.jpg`;

const VIEWPORT_CATEGORY_TO_POSTER_URL_TEMPLATE_FACTORY_MAP = {
  [SMALL_VIEWPORT]: mobilePosterUrlTemplateFactory,
  [MEDIUM_VIEWPORT]: desktopPosterUrlTemplateFactory,
  [LARGE_VIEWPORT]: desktopPosterUrlTemplateFactory
};

export const generatePosterUrl = (viewportCategory, categoryId) =>
  VIEWPORT_CATEGORY_TO_POSTER_URL_TEMPLATE_FACTORY_MAP[viewportCategory](
    categoryId
  );
