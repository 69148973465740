import { request } from "../";
import config from "../../../config/common";
import { CATEGORY_DETAILS_REQUEST, CATEGORY_REQUEST } from "../constants";
import getCategoryApiBaseUrl from "./getCategoryApiBaseUrl";
import getCategoryApiDefaults from "./getCategoryApiDefaults";
import filterCategories from "./filterCategories";
import getFacetedNavigationParams from "./getFacetedNavigationParams";

const {
  api: {
    category: { timeout, details }
  }
} = config;

const requestTimeout = parseInt(timeout, 10);

export const getCategory = ({
  country,
  language,
  query: { cid },
  uuid,
  xSiteOrigin,
  displayableChildLinkCount
}) => {
  const baseUrl = getCategoryApiBaseUrl(xSiteOrigin);

  if (!cid) {
    const error = new Error("Category API requires a category id");
    error.status = 400;
    error.statusText = "Bad request";

    return Promise.reject(error);
  }

  const queryString = new URLSearchParams(
    getCategoryApiDefaults({ country, language, baseUrl, xSiteOrigin })
  ).toString();

  const url = `${baseUrl}${cid}?${decodeURIComponent(queryString)}`;
  const options = {
    timeout: requestTimeout
  };
  const requestType = CATEGORY_REQUEST;

  return request(url, options, requestType, uuid).then(apiResponse =>
    filterCategories({ apiResponse, displayableChildLinkCount })
  );
};

export const getCategoryDetails = ({
  country,
  language,
  query: { cid, refine },
  uuid,
  xSiteOrigin
}) => {
  const baseUrl = getCategoryApiBaseUrl(xSiteOrigin);

  const combinedParams = {
    ...(refine ? getFacetedNavigationParams({ refine }) : {}),
    ...getCategoryApiDefaults({ country, language, baseUrl, xSiteOrigin })
  };

  const queryString = new URLSearchParams(combinedParams).toString();
  const url = `${baseUrl}${cid}${details}?${queryString}`;
  const options = { timeout: requestTimeout };
  const requestType = CATEGORY_DETAILS_REQUEST;

  return request(url, options, requestType, uuid);
};
