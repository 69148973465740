import PropTypes from "prop-types";
import { withTranslation } from "../translation";
import ShopWomenAndMen from "../ShopWomenAndMen";
import NotFoundMessage from "../NotFound/NotFoundMessage";
import { mensNewIn, womensNewIn } from "./constants";

export const CategoryOutOfStock = props => {
  const { formatLink, formatTranslation, isBrand } = props;
  const { errorMessage, suggestionMessage } = isBrand
    ? {
        errorMessage: formatTranslation("plp_web_oos_brand_page"),
        suggestionMessage: formatTranslation("plp_web_brand_suggestion_message")
      }
    : {
        errorMessage: formatTranslation("plp_web_oos_category_page"),
        suggestionMessage: ""
      };

  return (
    <div>
      <NotFoundMessage
        errorMessage={errorMessage}
        suggestionMessage={suggestionMessage}
      />
      <ShopWomenAndMen
        shopWomenLink={formatLink(womensNewIn)}
        shopWomenMessage={formatTranslation("plp_web_search_shop_women")}
        shopMenLink={formatLink(mensNewIn)}
        shopMenMessage={formatTranslation("plp_web_search_shop_men")}
      />
    </div>
  );
};

CategoryOutOfStock.propTypes = {
  isBrand: PropTypes.bool.isRequired,
  formatLink: PropTypes.func.isRequired,
  formatTranslation: PropTypes.func.isRequired
};

CategoryOutOfStock.displayName = "CategoryOutOfStock";

export default withTranslation(CategoryOutOfStock);
