import shouldLoadTreatments from "./shouldLoadTreatments";
import { loadCuratedCategoryTreatments } from "../../state/modules/category/actions/loadCategoryDetails";
import { clearCuratedCategoryTreatments } from "../../state/modules/category";

const refreshTreatmentsForCategory = async ({
  dispatch,
  query,
  country,
  decisions,
  isCuratedCategory
}) =>
  shouldLoadTreatments({ decisions, isCuratedCategory })
    ? dispatch(loadCuratedCategoryTreatments({ query, country }))
    : dispatch(clearCuratedCategoryTreatments());

export default refreshTreatmentsForCategory;
