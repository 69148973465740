import { useContext } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import CategoryHeader from "./../../../CategoryHeader";
import CategoryHeaderV2 from "./CategoryHeader";
import {
  getCategoryChildLinks,
  getCategoryId,
  getCategoryDetails
} from "../../../../../state/modules/category";
import { getViewportCategory } from "../../../../../state/modules/layout/selectors";
import { decisionsContext } from "../../../../../containers/Features";
import { PLP_FEATURED_HEADER } from "../../../../constants";
import * as types from "./constants";

const CategoryHeaderContainer = ({ scrollFunction }) => {
  const headerLinks = useSelector(getCategoryChildLinks);
  const hasHeaderLinks = !!headerLinks.length;
  const categoryId = useSelector(getCategoryId);
  const decisions = useContext(decisionsContext);
  const featuredHeaderDecisionParams = decisions?.[PLP_FEATURED_HEADER];
  const mediaType =
    featuredHeaderDecisionParams &&
    featuredHeaderDecisionParams.params[categoryId];

  if (!Object.values(types).includes(mediaType)) {
    return <CategoryHeader hasHeaderLinks={hasHeaderLinks} />;
  }

  const category = useSelector(getCategoryDetails);
  const viewportCategory = useSelector(getViewportCategory);

  return (
    <CategoryHeaderV2
      hasHeaderLinks={hasHeaderLinks}
      category={category}
      mediaType={mediaType}
      viewportCategory={viewportCategory}
      scrollFunction={scrollFunction}
    />
  );
};

CategoryHeaderContainer.propTypes = {
  scrollFunction: PropTypes.func.isRequired
};

export default CategoryHeaderContainer;
