const validFacetedNavigationFacetMatchers = [
  /^attribute_\d+$/,
  /^attr_\d+$/,
  /^range$/,
  /^base_colour$/,
  /^brand$/
];

const getFacetedNavigationParams = ({ refine }) => {
  const refinements = refine
    .split("|")
    .map(refinement => refinement.split(":"));

  const filteredRefinements = refinements.filter(([facetName, facetValues]) => {
    if (facetValues.includes(",")) {
      return false;
    }

    return validFacetedNavigationFacetMatchers.some(matcher =>
      matcher.test(facetName)
    );
  });

  return Object.fromEntries(filteredRefinements);
};

export default getFacetedNavigationParams;
