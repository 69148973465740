import { fetchCategory } from "../../state/modules/search";
import shouldGetCategory from "./shouldGetCategory";
import getSequentialDataWithCategoryRefresh from "./getSequentialDataWithCategoryRefresh";

const getSequentialData = async ({
  dispatch,
  store,
  query,
  country,
  language,
  uuid,
  xSiteOrigin,
  isNewPage,
  decisions
}) =>
  shouldGetCategory(isNewPage)
    ? getSequentialDataWithCategoryRefresh({
        dispatch,
        store,
        query,
        country,
        language,
        uuid,
        xSiteOrigin,
        isNewPage,
        decisions
      })
    : dispatch(fetchCategory(uuid, xSiteOrigin, isNewPage));

export default getSequentialData;
