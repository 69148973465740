import { isTestEndpoint } from "../../../state/modules/config/selectors";

export default ({ baseUrl, country, language, xSiteOrigin }) => {
  const { siteId, countryCode, keyStoreDataversion } = country;

  const defaultParams = {
    store: siteId,
    lang: language,
    depth: 1,
    country: countryCode,
    keyStoreDataversion
  };

  if (isTestEndpoint(baseUrl)) {
    defaultParams["x-site-origin"] = xSiteOrigin;
  }

  return defaultParams;
};
