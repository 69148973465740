import { useSelector } from "react-redux";
import { shouldRenderPagination } from "../../state/selectors";
import {
  getPaginationInitial,
  getPaginationMax
} from "../../state/modules/search";
import { getCanonicalUrlFromCategoryDetailsByLocale } from "../../state/modules/category/selectors";
import { withTranslation } from "../../components/translation";
import Pagination from "../../components/Pagination";
import { getCountryCode } from "../../state/modules/config/selectors";

const ConnectedPagination = props => (
  <Pagination
    {...props}
    shouldRender={useSelector(state => shouldRenderPagination(state))}
    currentPage={useSelector(state => getPaginationInitial(state))}
    maxPage={useSelector(state => getPaginationMax(state))}
    url={useSelector(state =>
      getCanonicalUrlFromCategoryDetailsByLocale(state)
    )}
    countryCode={useSelector(state => getCountryCode(state))}
  />
);

export default withTranslation(ConnectedPagination);
