import { useSelector } from "react-redux";
import { provideHooks } from "redial";
import CategoryPage from "./CategoryPage";
import {
  getItemCount,
  hasFacets,
  getPageNumber
} from "../../state/modules/search";
import { getIsLoading } from "../../state/modules/page/selectors";
import {
  getCategoryError,
  getCategoryTitle,
  getCategoryBreadcrumbs
} from "../../state/modules/category";

import hooks from "./hooks";

const ConnectedCategoryPage = props => {
  const categoryError = useSelector(getCategoryError);
  const pageHasFacets = useSelector(hasFacets);
  const itemCount = useSelector(getItemCount);
  const isLoading = useSelector(getIsLoading);
  const title = useSelector(getCategoryTitle);
  const pageNumber = useSelector(getPageNumber);
  const breadcrumbs = useSelector(getCategoryBreadcrumbs);

  return (
    <CategoryPage
      {...props}
      categoryError={categoryError}
      hasFacets={pageHasFacets}
      itemCount={itemCount}
      isLoading={isLoading}
      title={title}
      pageNumber={pageNumber}
      breadcrumbs={breadcrumbs}
    />
  );
};

export default provideHooks(hooks)(ConnectedCategoryPage);
