const filterCategories = ({ apiResponse, displayableChildLinkCount }) => {
  const {
    categories: { categories }
  } = apiResponse;

  apiResponse.categories.categories = categories
    .filter(category => category.isActive)
    .slice(0, displayableChildLinkCount);

  return apiResponse;
};

export default filterCategories;
