import PropTypes from "prop-types";
import styles from "./CategoryHeader.css";
import CategoryHeaderDescription from "./CategoryHeaderDescription";
import CategoryHeaderLinks from "./CategoryHeaderLinks";
import CategoryHeaderTitle from "./CategoryHeaderTitle";
import { CATEGORY_BANNER_ID } from "../constants";

export const CategoryHeader = ({ hasHeaderLinks }) => (
  <section id={CATEGORY_BANNER_ID} className={styles.wrapper}>
    <div className={styles.textWrapper}>
      <CategoryHeaderTitle />
      <CategoryHeaderDescription />
      {hasHeaderLinks && <CategoryHeaderLinks />}
    </div>
  </section>
);

CategoryHeader.propTypes = {
  hasHeaderLinks: PropTypes.bool.isRequired
};

export default CategoryHeader;
