import { useSelector } from "react-redux";
import { getCategoryDetails } from "../../../state/modules/category";

import CategoryHeaderTitle from "./CategoryHeaderTitle";

const CategoryHeaderTitleContainer = () => {
  const category = useSelector(getCategoryDetails);

  return <CategoryHeaderTitle category={category} />;
};

export default CategoryHeaderTitleContainer;
