import PropTypes from "prop-types";
import { generatePosterUrl } from "../CategoryHeaderVideo/generatePosterUrl";
import styles from "./index.css";

const CategoryHeaderImage = ({ viewportCategory, categoryId }) => (
  <div className={styles.categoryHeaderImage}>
    <img
      className={styles.image}
      src={generatePosterUrl(viewportCategory, categoryId)}
      alt=""
    />
  </div>
);

CategoryHeaderImage.propTypes = {
  categoryId: PropTypes.number.isRequired,
  viewportCategory: PropTypes.string.isRequired
};

export default CategoryHeaderImage;
