import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./CategoryHeaderDescription.css";

const CategoryHeaderDescription = ({ viewport, description }) => {
  if (!description) {
    return null;
  }

  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    setExpanded(false);
  }, [viewport]);

  return (
    <div className={styles.categoryHeaderDescription}>
      <div
        className={styles.button}
        onClick={() => setExpanded(!expanded)}
        onKeyPress={() => setExpanded(!expanded)}
        role="button"
        tabIndex="0"
      >
        <div className={styles.container}>
          <p
            className={classNames(styles.description, {
              [styles.descriptionExpanded]: expanded
            })}
          >
            {description}
          </p>
          <div
            className={classNames(styles.icon, {
              [styles.iconUp]: expanded
            })}
          />
        </div>
      </div>
    </div>
  );
};

CategoryHeaderDescription.propTypes = {
  description: PropTypes.string,
  viewport: PropTypes.string.isRequired
};

export default CategoryHeaderDescription;
