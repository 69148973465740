import { fetchCategory } from "../../state/modules/search";
import { loadCategory } from "../../state/modules/category/actions/loadCategoryDetails";
import refreshTreatmentsForCategory from "./refreshTreatments";
import { getCategoryIsCurated } from "../../state/modules/category/selectors";

const getSequentialDataWithCategoryRefresh = async ({
  dispatch,
  store,
  query,
  country,
  language,
  uuid,
  xSiteOrigin,
  isNewPage,
  decisions
}) =>
  dispatch(loadCategory({ query, country, language, uuid, xSiteOrigin }))
    .then(() =>
      refreshTreatmentsForCategory({
        dispatch,
        query,
        country,
        decisions,
        isCuratedCategory: getCategoryIsCurated(store.getState())
      })
    )
    .then(() => dispatch(fetchCategory(uuid, xSiteOrigin, isNewPage)));

export default getSequentialDataWithCategoryRefresh;
