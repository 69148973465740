import handleFetchFinish from "./handleFetchFinish";
import { setCategoryError } from "../../state/modules/category";
import shouldFetch from "./shouldFetch";
import { queryStringToObject } from "../../helpers/queryStringToObject";
import getParallelData from "./getParallelData";

const hooks = {
  fetch: ({
    dispatch,
    location,
    store,
    uuid,
    xSiteOrigin,
    isNewPage,
    shouldReloadCategoryDetails,
    refineForCategoryDetails,
    decisions
  }) => {
    const state = store.getState();

    if (!shouldFetch({ state, isNewPage })) {
      return;
    }

    const {
      config: {
        country,
        locale: { language }
      }
    } = state;
    const query = queryStringToObject(location?.search);

    return getParallelData({
      dispatch,
      store,
      query,
      country,
      language,
      uuid,
      xSiteOrigin,
      isNewPage,
      shouldReloadCategoryDetails,
      refineForCategoryDetails,
      decisions
    })
      .catch(error => {
        dispatch(setCategoryError(error));
      })
      .finally(() => {
        handleFetchFinish({ dispatch, isNewPage });
      });
  }
};

export default hooks;
