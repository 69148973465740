import PropTypes from "prop-types";
import styles from "./CategoryHeader.css";
import CategoryHeaderLinks from "../../../CategoryHeaderLinks";
import CategoryHeaderHero from "./CategoryHeaderHero";
import { CATEGORY_BANNER_ID } from "../../../../constants";
import * as types from "./constants";

export const CategoryHeader = ({
  hasHeaderLinks,
  category,
  mediaType,
  viewportCategory,
  scrollFunction
}) => (
  <section
    id={CATEGORY_BANNER_ID}
    className={styles.wrapper}
    aria-label="category banner video"
  >
    <div className={styles.textWrapper}>
      <CategoryHeaderHero
        category={category}
        mediaType={mediaType}
        viewportCategory={viewportCategory}
        scrollFunction={scrollFunction}
      />
      {hasHeaderLinks && <CategoryHeaderLinks />}
    </div>
  </section>
);

CategoryHeader.propTypes = {
  category: PropTypes.shape({
    headerBody: PropTypes.string,
    headerTitle: PropTypes.string
  }).isRequired,
  mediaType: PropTypes.oneOf(Object.values(types)).isRequired,
  hasHeaderLinks: PropTypes.bool.isRequired,
  viewportCategory: PropTypes.string.isRequired,
  scrollFunction: PropTypes.func.isRequired
};

export default CategoryHeader;
