import PropTypes from "prop-types";
import { useMatchMedia } from "../../../../../hooks";
import { withTranslation } from "../../../../../translation";
import styles from "./index.css";

const CategoryHeaderTitleOverlay = ({
  headerTitle,
  formatTranslation,
  scrollFunction
}) => {
  const prefersReducedMotion = useMatchMedia("(prefers-reduced-motion)");

  return (
    <div className={styles.CategoryHeaderTitleOverlay}>
      <h1 className={styles.headerTitle}>{headerTitle}</h1>
      <button className={styles.scrollToButton} onClick={scrollFunction}>
        <span className={styles.scrollToText}>
          {formatTranslation("plp_featured_header_cta")}
        </span>
        <span className={!prefersReducedMotion && styles.scrollToIcon} />
      </button>
    </div>
  );
};

CategoryHeaderTitleOverlay.propTypes = {
  headerTitle: PropTypes.string.isRequired,
  formatTranslation: PropTypes.func.isRequired,
  scrollFunction: PropTypes.func.isRequired
};

export default withTranslation(CategoryHeaderTitleOverlay);
