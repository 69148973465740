import PropTypes from "prop-types";
import { getHeaderTitle } from "../helpers.js";
import styles from "./CategoryHeaderTitle.css";

const CategoryHeaderTitle = ({
  category: { headerTitle, friendlyName, headerHtmlEncoded }
}) => {
  const header = getHeaderTitle({
    headerTitle,
    friendlyName,
    headerHtmlEncoded
  });

  return (
    <h1
      className={styles.categoryHeaderTitle}
      dangerouslySetInnerHTML={{
        __html: header
      }}
    />
  );
};

CategoryHeaderTitle.propTypes = {
  category: PropTypes.shape({
    headerTitle: PropTypes.string,
    friendlyName: PropTypes.string.isRequired,
    headerHtmlEncoded: PropTypes.string.isRequired
  }).isRequired
};

export default CategoryHeaderTitle;
