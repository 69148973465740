import { useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import CategoryHeader from "../../components/CategoryHeader";
import ErrorMessage from "../../components/ErrorMessage";
import ProductListingPage from "../ProductListingPage";
import CategoryOutOfStock from "../../containers/CategoryOutOfStock";
import Pagination from "../../containers/Pagination";
import ProductListSkeleton from "../../components/Results/ProductListSkeleton";
import { useBreadcrumbUpdates } from "../../components/hooks";

const CategoryPage = ({
  categoryError,
  itemCount,
  hasFacets,
  isLoading,
  title,
  pageNumber,
  breadcrumbs,
  ...rest
}) => {
  if (isLoading) {
    return <ProductListSkeleton />;
  }

  if (categoryError) {
    return <ErrorMessage {...categoryError} />;
  }

  if (itemCount === 0 && !hasFacets) {
    return <CategoryOutOfStock {...rest} />;
  }

  const facetsRef = useRef(null);

  useBreadcrumbUpdates(breadcrumbs);
  useEffect(() => {
    if (title && pageNumber === 1) {
      document.title = title;
    }
  }, [title]);

  const handleScroll = useCallback(() => {
    facetsRef.current.base.scrollIntoView({ behavior: "smooth" });
  }, [facetsRef]);

  return (
    <div>
      <CategoryHeader scrollFunction={handleScroll} />
      <ProductListingPage ref={facetsRef} {...rest} />
      <Pagination />
    </div>
  );
};

CategoryPage.propTypes = {
  categoryError: PropTypes.shape({
    status: PropTypes.number
  }),
  hasFacets: PropTypes.bool.isRequired,
  itemCount: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  pageNumber: PropTypes.number,
  breadcrumbs: PropTypes.array.isRequired
};

CategoryPage.defaultProps = {};

export default CategoryPage;
