import { useMatchMedia } from "../../../../../../hooks";
import getDataSavingMode from "./getDataSavingMode";

const shouldAutoPlay = () => {
  const prefersReducedMotion = useMatchMedia("(prefers-reduced-motion)");
  const isDataSavingMode = getDataSavingMode();

  return prefersReducedMotion === false && !isDataSavingMode;
};

export default shouldAutoPlay;
