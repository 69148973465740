import PropTypes from "prop-types";
import CategoryHeaderVideo from "../CategoryHeaderVideo";
import CategoryHeaderImage from "../CategoryHeaderImage";
import * as types from "../constants";

const CategoryHeaderMedia = ({ viewportCategory, categoryId, mediaType }) => {
  const CategoryHeaderMediaTypes = {
    image: CategoryHeaderImage,
    video: CategoryHeaderVideo
  };
  const Component = CategoryHeaderMediaTypes[mediaType];

  if (Component) {
    return (
      <Component categoryId={categoryId} viewportCategory={viewportCategory} />
    );
  }

  return null;
};

CategoryHeaderMedia.propTypes = {
  categoryId: PropTypes.number.isRequired,
  mediaType: PropTypes.oneOf(Object.values(types)).isRequired,
  viewportCategory: PropTypes.string.isRequired
};

export default CategoryHeaderMedia;
