import getClientConfigValue from "../../../client/getClientConfigValue";
import config from "../../../config/common";

const {
  api: {
    category: { path: categoryApiPath }
  }
} = config;

export default () => {
  if (SERVER) {
    return `${process.env.CATEGORY_API_HOSTNAME}${categoryApiPath}`;
  }

  if (getClientConfigValue("LOCAL_DEVELOPMENT") === "true") {
    return `/api${categoryApiPath}`;
  }

  return `${getClientConfigValue("BASE_URL")}${categoryApiPath}`;
};
