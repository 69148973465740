import { request } from "../";
import config from "../../../config/common";
import { CURATED_CATEGORY_REQUEST } from "../constants";
import getCuratedCategoryApiBaseUrl from "./getCuratedCategoryApiBaseUrl";

const {
  api: {
    curatedCategory: { timeout, version }
  }
} = config;

const requestTimeout = parseInt(timeout, 10);

export const getCuratedCategoryTreatments = ({
  query: { cid },
  country: { storeCode: store },
  uuid
}) => {
  const channel = "web";

  const baseUrl = getCuratedCategoryApiBaseUrl();

  const params = {
    store,
    channel,
    version
  };

  const queryString = new URLSearchParams(params).toString();
  const url = `${baseUrl}${cid}?${queryString}`;
  const options = { timeout: requestTimeout };
  const requestType = CURATED_CATEGORY_REQUEST;

  return request(url, options, requestType, uuid);
};
