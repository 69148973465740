import {
  SMALL_VIEWPORT,
  MEDIUM_VIEWPORT,
  LARGE_VIEWPORT
} from "../../../../../../state/modules/layout/constants";

const mobileVideoUrlTemplateFactory = categoryId =>
  `https://www.asos-video.com/video/upload/t_curated_category_mobile/_content_${categoryId}-mobile_Flash9_870xAuto_2500K.mp4`;

const desktopVideoUrlTemplateFactory = categoryId =>
  `https://www.asos-video.com/video/upload/t_curated_category_desktop/_content_${categoryId}-desktop_Flash9_2560xAuto_5000K.mp4`;

const VIEWPORT_CATEGORY_TO_VIDEO_URL_TEMPLATE_FACTORY_MAP = {
  [SMALL_VIEWPORT]: mobileVideoUrlTemplateFactory,
  [MEDIUM_VIEWPORT]: desktopVideoUrlTemplateFactory,
  [LARGE_VIEWPORT]: desktopVideoUrlTemplateFactory
};

export const generateVideoUrl = (viewportCategory, categoryId) =>
  VIEWPORT_CATEGORY_TO_VIDEO_URL_TEMPLATE_FACTORY_MAP[viewportCategory](
    categoryId
  );
